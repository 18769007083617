import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'grid-cols-1 md:grid-cols-1 bg-white wings-white p-0 sm:p-0 max-w-full',
    headline: 'text-black text-headline-lg md:text-headline-2xl',
    toggle: 'bg-white text-gray-600 text-body-sm sm:text-body-sm',
  },
});

export default Object.assign(CoverBox, { Prose });
