import { mergeProps } from '@/utils/merge';
import { StandaloneTrendingMenu } from 'base/components/TrendingMenu';

export const SvenskdamStandaloneTrendingMenu: typeof StandaloneTrendingMenu = (props) => {
  return (
    <StandaloneTrendingMenu
      {...mergeProps(
        {
          options: {
            className: 'hide-in-mobile-app',
          },
        },
        props,
      )}
    />
  );
};
