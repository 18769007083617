import { tw } from '@/utils/tw';
import { default as BaseBreadcrumbsTheme } from 'base/components/Breadcrumbs/theme';

const Breadcrumbs = tw.theme({
  extend: BaseBreadcrumbsTheme,
  slots: {
    step: `text-gray-700 text-ui-md`,
    separator: `text-gray-700`,
  },
});
export default Breadcrumbs;
