/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamStandaloneTrendingMenu as ResolvedStandaloneTrendingMenu } from 'sites/svenskdam/components/standalone/TrendingMenu';

export const TrendingMenu: typeof ResolvedStandaloneTrendingMenu = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTrendingMenu {...props} />
        </ErrorBoundary>
    );
});

export type TrendingMenuProps = PropsFromComponent<typeof TrendingMenu>;

/** @deprecated Use slot architecture instead */
export const TrendingMenuWith = (extras: DynamicStandaloneExtras): typeof TrendingMenu => {
    return function TrendingMenu(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TrendingMenu');
        return <ResolvedStandaloneTrendingMenu {...mergeProps({ options: { theme } }, props)} />;
    }
}